<template>
  <div class="page">
    <van-swipe class="banner" :autoplay="5000" indicator-color="white">
      <van-swipe-item>
        <img
          @click="inviter()"
          class="item"
          :src="require('../../../assets/images/seller/sellban-01.jpg')"
        />
      </van-swipe-item>
      <van-swipe-item>
        <img
          @click="help()"
          class="item"
          :src="require('../../../assets/images/seller/sellban-02.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <!-- <van-notice-bar
      left-icon="volume-o"
      text="微客：张** ,成功完成【工作】推荐订单,获得2350元分成奖励!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;微客：李** ,成功完成【证书】推荐订单,获得300元分成奖励!"
    >
    </van-notice-bar> -->
    <div class="title"><van-icon name="bar-chart-o" /> 流量数据分析</div>
    <div class="count">
      <van-row class="item">
        <van-col span="8" class="br">
          <div class="name">访问次数</div>
          <div class="total color-s1">
            <span class="text">总</span>{{ count.visitTotal
            }}<span class="text">次</span>
          </div>
          <van-row class="sub">
            <van-col span="10" class="text">昨日：</van-col>
            <van-col span="14" class="number"
              >{{ count.visitYesterday }}次</van-col
            >
          </van-row>
          <van-row class="sub">
            <van-col span="10" class="text">今日：</van-col>
            <van-col span="14" class="number">{{ count.visitToday }}次</van-col>
          </van-row>
        </van-col>
        <van-col span="8" class="br">
          <div class="name">访问人数</div>
          <div class="total color-s1">
            <span class="text">总</span>{{ count.userTotal
            }}<span class="text">人</span>
          </div>
          <van-row class="sub">
            <van-col span="10" class="text">昨日：</van-col>
            <van-col span="14" class="number"
              >{{ count.userYesterday }}次</van-col
            >
          </van-row>
          <van-row class="sub">
            <van-col span="10" class="text">今日：</van-col>
            <van-col span="14" class="number">{{ count.userToday }}次</van-col>
          </van-row>
        </van-col>
        <van-col span="8">
          <div class="name">注册人数</div>
          <div class="total color-s1">
            <span class="text">总</span>{{ count.regTotal
            }}<span class="text">人</span>
          </div>
          <van-row class="sub">
            <van-col span="10" class="text">昨日：</van-col>
            <van-col span="14" class="number"
              >{{ count.regYesterday }}次</van-col
            >
          </van-row>
          <van-row class="sub">
            <van-col span="10" class="text">今日：</van-col>
            <van-col span="14" class="number">{{ count.regToday }}次</van-col>
          </van-row>
        </van-col>
      </van-row>
      <div class="business">
        <van-tabs
          :active="busActive"
          :color="COLOR_M"
          border="true"
          @click="busChange"
        >
          <van-tab title="普工招聘统计" name="hire">
            <van-row class="item">
              <van-col span="12" class="br">
                <div class="name">付费查看</div>
                <div class="total color-s1">
                  <span class="text">总</span>{{ hire.buy.total
                  }}<span class="text">个</span>
                </div>
                <van-row class="sub">
                  <van-col span="12" class="text">本月：</van-col>
                  <van-col span="12" class="number"
                    >{{ hire.buy.month }}次</van-col
                  >
                </van-row>
                <van-row class="sub">
                  <van-col span="12" class="text">本周：</van-col>
                  <van-col span="12" class="number"
                    >{{ hire.buy.week }}次</van-col
                  >
                </van-row>
              </van-col>
              <van-col span="12">
                <div class="name">金豆结算</div>
                <div class="total color-s1">
                  <span class="text">总</span>{{ hire.settle.total
                  }}<span class="text">个</span>
                </div>
                <van-row class="sub">
                  <van-col span="12" class="text">本月：</van-col>
                  <van-col span="12" class="number"
                    >{{ hire.settle.month }}个</van-col
                  >
                </van-row>
                <van-row class="sub">
                  <van-col span="12" class="text">本周：</van-col>
                  <van-col span="12" class="number"
                    >{{ hire.settle.week }}个</van-col
                  >
                </van-row>
              </van-col>
            </van-row>
            <van-row class="btn">
              <van-col :span="24"
                ><van-button
                  :color="COLOR_M"
                  icon="share-o"
                  size="small"
                  @click="hireShare"
                  >立即去推广普工</van-button
                ></van-col
              >
            </van-row>
          </van-tab>
        </van-tabs>
      </div>
      <div class="analyse">
        <van-tabs
          :active="active"
          :color="COLOR_M"
          border="true"
          @click="chartChange"
        >
          <van-tab title="来访趋势" name="trend"> </van-tab>
          <van-tab title="来访渠道" name="source"></van-tab>
        </van-tabs>
        <div id="charts" class="charts"></div>
      </div>
    </div>
    <Tabbar active="home" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
