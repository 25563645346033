import { Swipe, SwipeItem, NoticeBar, Tabs, Tab, Image, Icon } from 'vant'
import Tabbar from '../common/Tabbar.vue'
import * as echarts from 'echarts'
export default {
    components: {
        Tabbar: Tabbar,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [NoticeBar.name]: NoticeBar,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Image.name]: Image,
        [Icon.name]: Icon
    },
    data () {
        return {
            loginState: 'N',
            busActive: 'hire',
            active: 'trend',
            count: {
                visitTotal: 0,
                visitYesterday: 0,
                visitToday: 0,
                userTotal: 0,
                userYesterday: 0,
                userToday: 0,
                regTotal: 0,
                regYesterday: 0,
                regToday: 0
            },
            hire: {
                buy: { total: 0, week: 0, month: 0 },
                settle: { total: 0, week: 0, month: 0 }
            },
            visitDay: [],
            seller: {}
        }
    },
    mounted () {
        // var query = this.$route.query
        this.init()
    },
    methods: {
        init () {
            this.retrieveSellerVisit()
            this.retrieveSellerUser()
            this.retrieveHireBuyCount()
            this.retrieveHireSettleCount()
            this.retrieveDayVisitCount()
        },
        help () {
            this.$router.push({ path: '/seller/help' })
        },
        async retrieveSellerVisit () {
            var sc = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: sc, config: 'TOTAL,TODAY,YESTERDAY' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/analyse/visit/retrieveSellerVisitCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.count.visitTotal = res.data.visitTotal
                this.count.visitYesterday = res.data.visitYesterday
                this.count.visitToday = res.data.visitToday
                this.count.userTotal = res.data.userTotal
                this.count.userYesterday = res.data.userYesterday
                this.count.userToday = res.data.userToday
            }
        },
        async retrieveSellerUser () {
            var sc = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: sc, config: 'TOTAL,TODAY,YESTERDAY' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveSellerUserCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.count.regTotal = res.data.total
                this.count.regYesterday = res.data.yesterday
                this.count.regToday = res.data.today
            }
        },
        async retrieveHireBuyCount () {
            var sc = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: sc }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskMKT/retrieveSellerBuyCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.hire.buy.total = res.data.total
                this.hire.buy.month = res.data.month
                this.hire.buy.week = res.data.week
            }
        },
        async retrieveHireSettleCount () {
            var sc = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: sc, businessType: 'HRE', config: 'TOTAL,WEEK,MONTH' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/settle/seller/retrieveSettleCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.hire.settle.total = res.data.total
                this.hire.settle.month = res.data.month
                this.hire.settle.week = res.data.week
            }
        },
        async retrieveDayVisitCount () {
            var sc = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: sc, day: 7 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/log/visit/retrieveSellerDayVisitCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.visitDay = res.data
                this.trendChart()
            }
        },
        trendChart () {
            var td = []
            var vd = []
            for (var i = this.visitDay.length - 1; i >= 0; i--) {
                var day = this.visitDay[i]
                td.push(day.date)
                vd.push(day.count)
            }
            var chartDom = document.getElementById('charts')
            echarts.init(chartDom).dispose()
            var myChart = echarts.init(chartDom)
            var option = {
                xAxis: {
                    type: 'category',
                    data: td
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: vd,
                        type: 'line',
                        smooth: true
                    }
                ]
            }
            myChart.setOption(option)
        },
        sourceChart () {
            var chartDom = document.getElementById('charts')
            echarts.init(chartDom).dispose()
            var myChart = echarts.init(chartDom)
            var option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: '匹配数量',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 100, name: '微信公众号' },
                            { value: 200, name: '抖音小程序' },
                            { value: 50, name: '其他' }
                        ]
                    }
                ]
            }
            myChart.setOption(option)
        },
        chartChange (val) {
            if (val === 'trend') {
                this.trendChart()
            } else if (val === 'source') {
                this.sourceChart()
            }
        },
        hireShare () {
            window.sessionStorage.setItem(window.const.global.HIRE_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/hire/home' })
        },
        inviter () {
            this.$router.push({ path: '/seller/inviter' })
        }
    }
}
